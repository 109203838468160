const EASY = 'EASY'
const MEDIUM = 'MEDIUM'
const HARD = 'HARD'
const DIFFICULTIES = [EASY, MEDIUM, HARD]

export default DIFFICULTIES

export {
  EASY,
  MEDIUM,
  HARD
}
